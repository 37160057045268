import React from "react";
import Slider from "react-slick";
import banner1 from "../../../images/baner1.png";
import banner2 from "../../../images/banner2.png";
import banner3 from "../../../images/banner3.png";
import banner4 from "../../../images/banner4.png";
import banner5 from "../../../images/banner5.png";
import banner6 from "../../../images/banner6.png";
import banner7 from "../../../images/banner7.png";
import banner8 from "../../../images/banner8.png";
const Footer = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidestoscroll: 1,
  };
  return (
    <div className="">
      <Slider {...settings}>
        <div>
          <img className="w-full" src={banner1} alt="banner1" />
        </div>
        <div>
          <img className="w-full" src={banner2} alt="banner3" />
        </div>
        <div>
          <img className="w-full" src={banner3} alt="banner3" />
        </div>
        <div>
          <img className="w-full" src={banner4} alt="banner4" />
        </div>
        <div>
          <img className="w-full" src={banner5} alt="banner5" />
        </div>
        <div>
          <img className="w-full" src={banner6} alt="banner6" />
        </div>
        <div>
          <img className="w-full" src={banner7} alt="banner7" />
        </div>
        <div>
          <img className="w-full" src={banner8} alt="banner8" />
        </div>
        {/* <div>
          <img className="w-full" src={footerimage} alt="banner9" />
        </div> */}
      </Slider>
      {/* <img src={footerimage} alt="" className="w-full h-auto lg:h-[600px]" /> */}
    </div>
  );
};

export default Footer;
