import React, { useState } from "react";
import "./content.css";
import { IconQuoteRight } from "../../components/Icon";
import ds1 from "../../images/ds1.png";
import ds2 from "../../images/ds2.png";
import ds3 from "../../images/ds3.png";
import ds4 from "../../images/ds4.png";
import ds5 from "../../images/ds5.png";
import ds6 from "../../images/ds6.png";
import ds7 from "../../images/ds7.png";
import ds8 from "../../images/ds8.png";
import lmokvip from "../../images/lienminhokvip.png";
import sl1 from "../../images/sl1.png";
import sl2 from "../../images/sl2.png";
import sl3 from "../../images/sl3.png";
import sl4 from "../../images/sl4.png";
import vongquay from "../../images/vongquay.gif";
import bgvongquay from "../../images/bg-vongquay.png";
// import bgvongquays from "../../images/bg-vongquays.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import ModalVideo from "../../components/ModalVideo/ModaVideo";
import ModalBlog from "../../components/ModalVideo/ModataBlog";

//  slide dai su thuong hieu
const slide = [
  { img: ds1 },
  { img: ds2 },
  { img: ds3 },
  { img: ds4 },
  { img: ds5 },
  { img: ds6 },
  { img: ds7 },
  { img: ds8 },
];
const Content = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenBlog, setIsModalOpenBlog] = useState(false);

  return (
    <div className="">
      <div className="pb-14 bg-home">
        <div className="flex flex-col-reverse lg:flex-row justify-between gap-[28px] max-w-[1600px] mx-auto h-auto !items-center">
          <div className="w-full lg:w-1/2 flex flex-col gap-y-7 text-center px-2.5">
            <h2 className="font-bold text-[21px] md:text-[38px] lg:text-[24px] xl:text-[40px] text-center">
              TRANG <span className="text-[#ff9900]">LIÊN MINH</span> CÁC THƯƠNG
              HIỆU GIẢI TRÍ TRỰC TUYẾN{" "}
              <span className="text-[#ff9900]">HÀNG ĐẦU CHÂU Á</span>
            </h2>
            <p className="text-[16px] lg:text-[18px] text-center">
              <span className="text-[#F90]">Liên Minh OKVIP </span> tự hào mang
              tới Hệ sinh thái đa dạng các thương hiệu giải trí hàng đầu, an
              toàn, bảo mật và Hệ thống Game show miễn phí, hấp dẫn, tặng thưởng
              giá trị cao!
            </p>
            <div className="w-full flex justify-center lg:justify-start">
              <ModalVideo
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </div>
          {/* <div
            className="relative w-[80%] h-[270px] lg:h-[370px] 2xl:h-[520px] bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${bgvongquay})`,
            }}
          >
            <img
              src={vongquay}
              alt="gif"
              className="absolute !w-[250px] !h-[250px] lg:!w-[310px] lg:!h-[380px] 2xl:!w-[500px] 2xl:!h-[460px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div> */}
          <div className="w-full lg:w-1/2 pb-5 bg-mobile flex justify-center !items-center lg:py-10">
            <div className="relative size-[360px] xl:w-[650px] xl:h-[500px]">
              <img
                className="absolute size-[300px] xl:size-[480px] left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2"
                loading="lazy"
                src={vongquay}
                alt=""
              />
              <img
                className="absolute h-[320px]  xl:w-[600px] xl:h-[500px] left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2"
                loading="lazy"
                src={bgvongquay}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1600px] mx-auto px-2.5">
        {/* dai su thuong hieu  */}
        <div className="mt-5">
          <h2 className="font-bold text-[18px] lg:text-[36.5px] text-center lg:text-left">
            ĐẠI SỨ THƯƠNG HIỆU TẠI{" "}
            <span className="text-[#ff9900]">LIÊN MINH OKVIP</span>
          </h2>
          <div className="py-5 mx-auto max-w-[1200px]">
            <Swiper
              className="slidedaisu"
              slidesPerView={"auto"}
              centeredSlides={true}
              spaceBetween={70}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
            >
              {slide.map((item, index) => (
                <SwiperSlide key={index}>
                  <img className="w-full h-full" src={item.img} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        {/* hieu ve lien minh okvip  */}
        <div className="flex flex-col lg:flex-row justify-between items-center gap-5">
          <div className="w-full lg:w-1/2">
            <h2 className=" font-bold text-[20px] text-center lg:text-left lg:text-[40px] py-2.5 font-cafeta">
              HIỂU THẾ NÀO VỀ
              <span className="text-[#ff9900]"> LIÊN MINH OKVIP</span>
            </h2>
            <p className="text-justify text-[15px] lg:text-[20px]">
              LIÊN MINH OKVIP là một trang Liên Minh các thương hiệu giải trí
              trực tuyến hàng đầu Châu Á.
            </p>

            <p className="text-justify text-[15px] lg:text-[20px]">
              Định vị của OKVIP là một Liên Minh, điều này tạo nên sự khác biệt
              so với các nhà cái hay trang games riêng lẻ. Tại Trang chủ Liên
              Minh OKVIP, không diễn ra các hoạt động cá cược, không yêu cầu
              khách hàng nạp tiền hay cung cấp thông tin cá nhân phức tạp. Thay
              vào đó, khách hàng có thể nhận được nhiều ưu đãi miễn phí và những
              giá trị to lớn khác. Thuật ngữ “Liên Minh” còn thể hiện sự gắn
              kết, sức mạnh vượt trội và sự khác biệt hoàn toàn so với những nhà
              cái thông thường.
            </p>
            <ModalBlog
              isModalOpenBlog={isModalOpenBlog}
              setIsModalOpenBlog={setIsModalOpenBlog}
            />
          </div>
          <div className="w-full lg:w-1/2 flex justify-center">
            <img className="w-4/5" src={lmokvip} alt="" />
          </div>
        </div>
        {/* chinh sach nhan su  */}
        <div className="">
          <h2 className=" text-[30px] text-center lg:text-left lg:text-[40px] font-bold py-2.5">
            CHÍNH SÁCH <span className="text-[#ff9900]">NHÂN SỰ</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 lg:gap-10">
            <div className="bg-gradient-to-r  from-white/10 via-black/10 to-white/10 rounded-xl p-2.5 text-center flex justify-center gap-2 flex-col border-[3px] border-l-white/50 border-t-white/50 border-r-gray-500/50 border-b-gray-500/50">
              <p className="size-8 text-center flex justify-center items-center rounded-full bg-[#ff9900] text-white mx-auto ">
                1
              </p>
              <h3 className="text-[20px] lg:text-[36px] font-bold ">Yêu Cầu</h3>
              <p className="text-[16px] md:text-[15px] lg:text-[22px] text-white/80">
                Ứng viên phải trên 18 tuổi và có máy tính hoặc laptop, am hiểu
                mạng xã hội là 1 lợi thế
              </p>
            </div>
            <div className="bg-gradient-to-r from-white/10 via-black/10 to-white/10 rounded-xl p-2.5 text-center flex justify-center gap-2 flex-col border-[3px] border-l-white/50 border-t-white/50 border-r-gray-500/50 border-b-gray-500/50">
              <p className="size-8 text-center flex justify-center items-center rounded-full bg-[#ff9900] text-white mx-auto ">
                2
              </p>
              <h3 className="text-[20px] lg:text-[36px] font-bold ">
                Địa điểm làm việc
              </h3>
              <p className="text-[16px] md:text-[15px] lg:text-[22px] text-white/80 text-center">
                Cả thế giới đều là nơi làm việc của bạn. Chỉ với 1 chiếc laptop,
                bạn có thể làm việc tại bất cứ đâu kể cả quán cà phê
              </p>
            </div>
            <div className="bg-gradient-to-r from-white/10 via-black/10 to-white/10 rounded-xl p-2.5 text-center flex justify-center gap-2 flex-col border-[3px] border-l-white/50 border-t-white/50 border-r-gray-500/50 border-b-gray-500/50">
              <p className="size-8 text-center flex justify-center items-center rounded-full bg-[#ff9900] text-white mx-auto ">
                3
              </p>
              <h3 className="text-[20px] lg:text-[36px] font-bold ">
                Mục tiêu công việc
              </h3>
              <p className="text-[16px] md:text-[15px] lg:text-[22px] text-white/80 text-center ">
                Mục tiêu chính là tìm kiếm khách hàng đăng ký tài khoản và tham
                giá cá cược trại trang game bạn chọn làm việc
              </p>
            </div>
          </div>
          <p className="py-5 text-center text-[14px] lg:text-[24px]">
            *khi nhân việc ứng viên sẽ được cấp 1 đường link đại lý cá nhân dùng
            để gửi cho khách hàng đăng ký tài khoản
          </p>
        </div>
        <div className=" lg:p-10 grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-[60px] items-center lg:border-[3px] border-l-white/50 border-t-white/50 border-r-gray-500/50 border-b-gray-500/50 lg:bg-gradient-to-r from-white/10 via-black/10 to-white/10 rounded-xl">
          <div className="rounded-md p-2.5 text-center flex justify-center gap-2 flex-col ">
            <p className="size-8 text-center flex justify-center items-center rounded-full bg-[#ff9900] text-white mx-auto ">
              4
            </p>
            <h3 className="text-[20px] lg:text-[26px] xl:text-[36px] font-bold uppercase text-white">
              Mô tả công việc
            </h3>
            <p className="text-[16px] lg:text-[22px] text-white/80">
              Có 2 cách để ứng viên có thể tìm kiếm khách hàng cho mình:
            </p>
          </div>

          <div className="bg-white p-2.5 rounded-lg text-black text-center text-[16px] lg:text-[20px] relative">
            <div className="flex justify-end lg:justify-center -mt-8 lg:-mt-10">
              <IconQuoteRight
                className={"text-center text-[#ff9900] size-10 lg:size-14"}
              />
            </div>
            <p>
              <b>Cách thứ nhất: TRUYỀN THỐNG </b>
            </p>
            <p className="">
              Đăng bài về các chương trình khuyến mãi đang hoạt động trên trang
              lên các hội nhóm FACEBOOK hoặc các trang mạng xã hội khác.
            </p>
            <p className="">
              Spam bình luận hoặc nhắn tin trực tiếp để mời khách hàng tham gia,
              giới thiệu cho những bạn bè đang chơi trong lĩnh vực casino online
            </p>
            <p>Để mời họ đăng ký tham gia bên trang của mình làm việc.</p>
          </div>
          <div className="bg-white p-2.5 rounded-lg text-black text-center text-[16px] lg:text-[20px]">
            <div className="flex justify-end lg:justify-center -mt-8 lg:-mt-10">
              <IconQuoteRight
                className={"text-center text-[#ff9900] size-10 lg:size-14"}
              />
            </div>
            <p>
              <b>*Cách thứ hai: HIỆN ĐẠI</b>
            </p>
            <p className="">
              Livetreams trên các nền tảng mạng xã hội, chủ yếu nhất là tiktok
              và FB.
            </p>
            <p className="">
              Công ty sẽ cấp cho nhân viên 1 tài khoản Demo, nhân viên sẽ dùng
              tài khoản đó để tham gia bất kỳ sản phầm cá cược nào để thu hút
              người xem
            </p>
            <p className="">
              Từ đó tìm kiếm cho mình các khách hàng tìm năng. Với cách tìm kiếm
              khách hàng hiện đại này ứng viên thường xuyên được người xem tặng
              quà khi họ thắng cược. Chính vì thế tìm kiếm khách hàng theo cách
              hiện đại mang đến cho ứng viên nguồn thu nhập cao hơn rất nhiều so
              với cách truyền thống.
            </p>
          </div>
        </div>
        {/* dai ngo nhan vien  */}

        <div className="my-10">
          <h2 className="font-bold text-[30px] text-center lg:text-left lg:text-[40px] py-2.5 my-5">
            ĐÃI NGỘ <span className="text-[#ff9900]">NHÂN VIÊN</span>
          </h2>
          <div className="grid grid-cols-2 xl:flex flex-row justify-between gap-0 xl:gap-x-10 text-center box-border">
            <div className="border-r border-gray-500/30 xl:border-none p-1.5 lg:p-0">
              <h4 className="text-[20px] font-bold lg:text-[40px] text-center">
                Lương Cứng
              </h4>
              <p className="text-[15px]">
                7 triệu tăng dần đến 15triệu/tháng. <br /> Thanh toán vào ngày
                15 hàng tháng <br /> bắng hình thức chuyển khoản
              </p>
            </div>
            <div className="h-[106px] w-[1px] bg-white/30 hidden xl:block"></div>
            <div className="lg:border-none p-1.5 lg:p-0">
              <h4 className="text-[20px] font-bold lg:text-[40px] text-center">
                Hoa Hồng
              </h4>
              <p className="text-[15px]">
                Hoa hồng tối thiểu 9% và cao nhất{" "}
                <br className="hidden lg:block" /> đến 22%{" "}
                <br className="hidden lg:block" /> cùng nhiều mốc thưởng KPI
                khác.
              </p>
            </div>
            <div className="h-[106px] w-[1px] bg-white/30 hidden xl:block"></div>
            <div className="border-r border-t border-gray-500/30 xl:border-none p-1.5 lg:p-0">
              <h4 className="text-[20px] font-bold lg:text-[40px] text-center">
                Thời gian
              </h4>
              <p className="text-[15px]">
                Làm việc 6h/ngày. Mỗi tháng <br className="hidden lg:block" />{" "}
                được off 4 ngày bất kì.
              </p>
            </div>
            <div className="h-[106px] w-[1px] bg-white/30 hidden xl:block"></div>
            <div className="border-t border-gray-500/30 xl:border-none p-1.5 lg:p-0">
              <h4 className="text-[20px] font-bold lg:text-[40px] text-center">
                Kinh nghiệm
              </h4>
              <p className="text-[15px]">
                Không yêu cầu kinh nghiệm. khi{" "}
                <br className="hidden lg:block" /> nhận việc sẽ được đào tạo
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10  bg-[#020519]">
        <div className="max-w-[1600px] mx-auto ">
          <div className="">
            <Swiper
              className="slicedaingo"
              slidesPerView={"auto"}
              spaceBetween={20}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
              breakpoints={{
                375: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                425: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              <SwiperSlide>
                <img src={sl1} alt="" loading="lazy" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={sl2} alt="" loading="lazy" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={sl3} alt="" loading="lazy" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={sl4} alt="" loading="lazy" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
