import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hr from "../../components/Hr";
import { getByUserCode } from "../../services/user";
import Content from "./Content";
const Home = () => {
  const [hrData, setHrData] = useState();
  const { userCode } = useParams();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const res = await getByUserCode({
      userCode: userCode,
    });
    setHrData(res);
  };

  return (
    <>
      {hrData?._id ? (
        <Hr hrData={hrData} />
      ) : (
        <div className="text-white">
          <Content />
        </div>
      )}
    </>
  );
};

export default Home;
