import logook9x from "../../../images/logook9x.png";
import phoneok9 from "../../../images/ok9phone.png";
import phone78win from "../../../images/78winphone.png";
import logo78win from "../../../images/logo78win.png";
import qr789 from "../../../images/qr789bet.png";
import qrjun from "../../../images/qrjun88.png";
import qrsh from "../../../images/qrshbet.png";
import qrnew from "../../../images/qrnew88.png";
import qrhi from "../../../images/qrhi88.png";
import qr78win from "../../../images/qr78win.png";
import qrok9 from "../../../images/qrok9.png";
import qrmb from "../../../images/qrmb66.png";
import qrf8 from "../../../images/qrf8.png";
import link789 from "../../../images/link789bet.png";
import phone789 from "../../../images/phone789.png";
import logojun88 from "../../../images/logo-jun88.png";
import phonejun88 from "../../../images/jun88phone.png";
import logonew88 from "../../../images/logonew88.png";
import phonenew88 from "../../../images/new88phone.png";
import logoshbet from "../../../images/logoshbet.png";
import phoneshbet from "../../../images/shbetphone.png";
import logof8 from "../../../images/logof8.png";
import phonef8 from "../../../images/f8phone.png";
import logohi88 from "../../../images/logohi88.png";
import phonehi88 from "../../../images/hiphone.png";
import logomb66 from "../../../images/logomb66.png";
import phonemocbai from "../../../images/mb66phone.png";
import logo789bet from "../../../images/789bet.png";

export const contentHauDai = [
  {
    name: "789BET",
    imgPhone: phone789,
    imgLogo: logo789bet,
    link: link789,
    href: "https://seven89betcd01.onrender.com/",
    qr: qr789,
    content: ` 
                    789BET là một nhà cái có giấy phép cá cược trực tuyến hợp
                    pháp do Isle of Man và Khu kinh tế Cagayan and Freeport cấp.
                    Với bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu
                    người chơi, 789BET đã và đang khẳng định vị thế của mình
                    trên thị trường game trực tuyến .Với tư cách là một công ty
                    trò chơi trực tuyến trực thuộc Tổng bộ Liên minh OKVIP có
                    trụ sở tại Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên
                    nghiệp đông đảo cung cấp sản phẩm phục vụ chất lượng cao.
                  
                  
                    789BET đảm bảo không tiết lộ thông tin cá nhân khách hàng
                    cho bất kỳ bên thứ ba nào , sử dụng tiêu chuẩn mã hoá dữ
                    liệu ở mức cao nhất. Tất cả thông tin cá nhân đều được thông
                    qua hệ thống bảo mật - Secure Socket (Chuẩn mã hóa SS
                    128-bit), đồng thời được bảo vệ trong môi trường quản lý an
                    toàn đảm bảo không thể truy cập từ các khu vực mạng công
                    cộng. Tất cả dữ liệu ra vào đều bị hạn chế, giám sát nghiêm
                    ngặt và quản lý chặt chẽ nhằm mang đến cho người chơi trải
                    nghiệm người dùng an toàn tuyệt đối.
                  `,
  },
  {
    name: "JUN88",
    imgPhone: phonejun88,
    imgLogo: logojun88,
    link: link789,
    href: "https://jun88p2cd04.pages.dev/",
    qr: qrjun,
    content: ` 
                    Jun88 là một nhà cái có giấy phép cá cược trực tuyến hợp
                    pháp do Isle of Man và Khu kinh tế Cagayan and Freeport cấp.
                    Với bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu
                    người chơi, Jun88 đã và đang khẳng định vị thế của mình trên
                    thị trường game trực tuyến. Với tư cách là một công ty trò
                    chơi trực tuyến trực thuộc Tổng bộ Liên Minh OKVIP có trụ sở
                    tại Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp
                    đông đảo cung cấp sản phẩm phục vụ chất lượng cao. Jun88 đảm
                    bảo không tiết lộ thông tin cá nhân khách hàng cho bất kỳ
                    bên thứ ba nào, sử dụng tiêu chuẩn mã hóa dữ liệu ở mức cao
                    nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống
                    bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời
                    được bảo vệ trong môi trường quản lý an toàn đảm bảo không
                    thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu
                    ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt
                    chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an
                    toàn tuyệt đối.
                 `,
  },
  {
    name: "NEW88",
    imgPhone: phonenew88,
    imgLogo: logonew88,
    link: link789,
    qr: qrnew,
    href: "https://new888a.net/",

    content: `
                    NEW88 là một nhà cái có giấy phép cá cược trực tuyến hợp
                    pháp do Isle of Man và Khu kinh tế Cagayan and Freeport cấp.
                    Với bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu
                    người chơi, NEW88 đã và đang khẳng định vị thế của mình trên
                    thị trường game trực tuyến. Với tư cách là một công ty trò
                    chơi trực tuyến trực thuộc Tổng bộ Liên Minh OKVIP có trụ sở
                    tại Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp
                    đông đảo cung cấp sản phẩm phục vụ chất lượng cao. NEW88 đảm
                    bảo không tiết lộ thông tin cá nhân khách hàng cho bất kỳ
                    bên thứ ba nào , sử dụng tiêu chuẩn mã hóa dữ liệu ở mức cao
                    nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống
                    bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời
                    được bảo vệ trong môi trường quản lý an toàn đảm bảo không
                    thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu
                    ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt
                    chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an
                    toàn tuyệt đối.
                  `,
  },
  {
    name: "SHBET",
    imgPhone: phoneshbet,
    imgLogo: logoshbet,
    link: link789,
    qr: qrsh,
    href: "https://shbetb.life/?f=3718936",
    content: `
                    SHBET là một nhà cái có giấy phép cá cược trực tuyến hợp
                    pháp do Isle of Man và Khu kinh tế Cagayan and Freeport cấp.
                    Với bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu
                    người chơi, SHBET đã và đang khẳng định vị thế của mình trên
                    thị trường game trực tuyến. Với tư cách là một công ty trò
                    chơi trực tuyến trực thuộc Tổng bộ Liên Minh OKVIP có trụ sở
                    tại Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp
                    đông đảo cung cấp sản phẩm phục vụ chất lượng cao. SHBET đảm
                    bảo không tiết lộ thông tin cá nhân khách hàng cho bất kỳ
                    bên thứ ba nào , sử dụng tiêu chuẩn mã hóa dữ liệu ở mức cao
                    nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống
                    bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời
                    được bảo vệ trong môi trường quản lý an toàn đảm bảo không
                    thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu
                    ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt
                    chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an
                    toàn tuyệt đối.
                  `,
  },
  {
    name: "F8BET",
    imgPhone: phonef8,
    imgLogo: logof8,
    link: link789,
    qr: qrf8,
    href: "https://chqfngbivgo7fo7x27dlpncbkj3x2efmmoxu6tknczulft5xzazybxq.f8bet34.vip/",

    content: `
                    F8BET là một nhà cái có giấy phép cá cược trực tuyến hợp
                    pháp do Isle of Man và Khu kinh tế Cagayan and Freeport cấp.
                    Với bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu
                    người chơi, F8BET đã và đang khẳng định vị thế của mình trên
                    thị trường game trực tuyến. Với tư cách là một công ty trò
                    chơi trực tuyến trực thuộc Tổng bộ Liên Minh OKVIP có trụ sở
                    tại Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp
                    đông đảo cung cấp sản phẩm phục vụ chất lượng cao. F8BET đảm
                    bảo không tiết lộ thông tin cá nhân khách hàng cho bất kỳ
                    bên thứ ba nào , sử dụng tiêu chuẩn mã hóa dữ liệu ở mức cao
                    nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống
                    bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời
                    được bảo vệ trong môi trường quản lý an toàn đảm bảo không
                    thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu
                    ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt
                    chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an
                    toàn tuyệt đối.
                 `,
  },
  {
    name: "Hi88",
    imgPhone: phonehi88,
    imgLogo: logohi88,
    link: link789,
    href: "https://tfw91xhfljgheajkcrxo.hi4444.vip/pc.php",

    qr: qrhi,
    content: `      Hi88 là một nhà cái có giấy phép cá cược trực tuyến hợp pháp
                    do Isle of Man và Khu kinh tế Cagayan and Freeport cấp. Với
                    bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu người
                    chơi, Hi88 đã và đang khẳng định vị thế của mình trên thị
                    trường game trực tuyến. Với tư cách là một công ty trò chơi
                    trực tuyến trực thuộc Tổng bộ Liên Minh OKVIP có trụ sở tại
                    Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp đông
                    đảo cung cấp sản phẩm phục vụ chất lượng cao. Hi88 đảm bảo
                    không tiết lộ thông tin cá nhân khách hàng cho bất kỳ bên
                    thứ ba nào, sử dụng tiêu chuẩn mã hóa dữ liệu ở mức cao
                    nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống
                    bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời
                    được bảo vệ trong môi trường quản lý an toàn đảm bảo không
                    thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu
                    ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt
                    chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an
                    toàn tuyệt đối.
                  `,
  },
  {
    name: "MB66",
    imgPhone: phonemocbai,
    imgLogo: logomb66,
    link: link789,
    href: "https://mb6669.cc/",

    qr: qrmb,
    content: ` MB66 là một nhà cái có giấy phép cá cược trực tuyến hợp pháp
                    do Isle of Man và Khu kinh tế Cagayan and Freeport cấp. Với
                    bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu người
                    chơi, MB66 đã và đang khẳng định vị thế của mình trên thị
                    trường game trực tuyến. Với tư cách là một công ty trò chơi
                    trực tuyến trực thuộc Tổng bộ Liên Minh OKVIP có trụ sở tại
                    Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp đông
                    đảo cung cấp sản phẩm phục vụ chất lượng cao. MB66 đảm bảo
                    không tiết lộ thông tin cá nhân khách hàng cho bất kỳ bên
                    thứ ba nào , sử dụng tiêu chuẩn mã hóa dữ liệu ở mức cao
                    nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống
                    bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời
                    được bảo vệ trong môi trường quản lý an toàn đảm bảo không
                    thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu
                    ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt
                    chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an
                    toàn tuyệt đối.`,
  },
  {
    name: "OK9",
    imgPhone: phoneok9,
    imgLogo: logook9x,
    link: link789,
    href: "https://ok915.vip/",
    qr: qrok9,
    content: `  OK9 là một nhà cái có giấy phép cá cược trực tuyến hợp pháp
                    do Isle of Man và Khu kinh tế Cagayan and Freeport cấp. Với
                    bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu người
                    chơi, OK9 đã và đang khẳng định vị thế của mình trên thị
                    trường game trực tuyến .Với tư cách là một công ty trò chơi
                    trực tuyến trực thuộc Tổng bộ Liên minh OKVIP có trụ sở tại
                    Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp đông
                    đảo cung cấp sản phẩm phục vụ chất lượng cao. OK9 đảm bảo
                    không tiết lộ thông tin cá nhân khách hàng cho bất kỳ bên
                    thứ ba nào , sử dụng tiêu chuẩn mã hoá dữ liệu ở mức cao
                    nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống
                    bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời
                    được bảo vệ trong môi trường quản lý an toàn đảm bảo không
                    thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu
                    ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt
                    chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an
                    toàn tuyệt đối.`,
  },
  {
    name: "78win",
    imgPhone: phone78win,
    imgLogo: logo78win,
    link: link789,
    href: "https://78win99.vip/",

    qr: qr78win,
    content: `  78win là một nhà cái có giấy phép cá cược trực tuyến hợp
                    pháp do Isle of Man và Khu kinh tế Cagayan and Freeport cấp.
                    Với bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu
                    người chơi, 78win đã và đang khẳng định vị thế của mình trên
                    thị trường game trực tuyến. Với tư cách là một công ty trò
                    chơi trực tuyến trực thuộc Tổng bộ Liên Minh OKVIP có trụ sở
                    tại Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp
                    đông đảo cung cấp sản phẩm phục vụ chất lượng cao. 78win đảm
                    bảo không tiết lộ thông tin cá nhân khách hàng cho bất kỳ
                    bên thứ ba nào , sử dụng tiêu chuẩn mã hóa dữ liệu ở mức cao
                    nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống
                    bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời
                    được bảo vệ trong môi trường quản lý an toàn đảm bảo không
                    thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu
                    ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt
                    chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an
                    toàn tuyệt đối.`,
  },
];
