import ds1 from "../../../images/lm1.jpg";
import ds2 from "../../../images/lm2.png";
import ds3 from "../../../images/lm3.png";
import ds4 from "../../../images/lm4.png";
import ds5 from "../../../images/lm5.png";
import ds6 from "../../../images/lm6.png";
import ds7 from "../../../images/lm7.png";
import ds8 from "../../../images/lm8.png";
import ds9 from "../../../images/lm9.png";
import ds1m from "../../../images/ds1m.jpg";
import ds2m from "../../../images/ds2m.png";
import ds3m from "../../../images/ds3m.jpg";
import ds4m from "../../../images/ds4m.jpg";
import ds5m from "../../../images/ds5m.jpg";
import ds6m from "../../../images/ds6m.jpg";
import ds7m from "../../../images/ds7m.jpg";
import ds8m from "../../../images/ds8m.jpg";
import ds9m from "../../../images/ds9m.png";
import ds10m from "../../../images/ds10m.jpg";
import ds11m from "../../../images/ds11m.jpg";
import "./daisu.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { CircleChevronLeft, CircleChevronRight } from "../../Icon";
const SlideDaiSu = () => {
  const slides = [
    { img: ds1 },
    { img: ds2 },
    { img: ds3 },
    { img: ds4 },
    { img: ds5 },
    { img: ds6 },
    { img: ds7 },
    { img: ds8 },
    { img: ds9 },
  ];
  const slidesWithDuplicates = [...slides, ...slides];
  return (
    <div className="max-w-[1600px]">
      <h3 className="text-[#fff] text-[30px] md:text-[41px] pt-2 pb-5 font-bold text-center">
        ĐẠI SỨ THƯƠNG HIỆU
      </h3>
      {/* dai su thuong hieu mobi  */}
      <div className="block xl:hidden">
        <Swiper
          loop={true}
          slidesPerView={1}
          spaceBetween={20}
          slideToClickedSlide={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          className="daisuthuonghieumobi"
        >
          <SwiperSlide>
            <img src={ds1m} alt="ds1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds2m} alt="ds2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds3m} alt="ds3" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds4m} alt="ds4" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds5m} alt="ds5" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds6m} alt="ds6" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds7m} alt="ds7" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds8m} alt="ds8" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds9m} alt="ds9" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds10m} alt="ds9" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ds11m} alt="ds9" />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* dai su thuong hieu pc  */}
      <div className="hidden xl:block relative">
        <Swiper
          className="daisuthuonghieu"
          loop={true}
          slidesPerView={"auto"}
          spaceBetween={20}
          navigation={{
            nextEl: ".daisuthuonghieu-next",
            prevEl: ".daisuthuonghieu-prev",
          }}
          // pagination={{
          //   el: ".daisuthuonghieupagination",
          //   clickable: true,

          //   renderBullet: (index, className) => {

          //     return `<span class=" ${className}" >  </span>`;
          //   },
          // }}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation, Pagination]}
        >
          {slidesWithDuplicates.map((item, index) => (
            <SwiperSlide
              key={index}
              style={
                index === 0 || index === slides.length
                  ? {
                      minWidth: "1050px",
                      minHeight: "590px !important",
                      maxHeight: "590px !important",
                      objectFit: "cover",
                    }
                  : {}
              }
            >
              <img loading="lazy" src={item.img} alt={"ds" + index} />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <div className="daisuthuonghieupagination flex justify-center max-w-fit gap-x-1 items-center my-4 bottom-10 text-center z-[99999] bg-[#191919] "></div> */}
        <div className="flex absolute justify-between cursor-pointer top-1/2 -translate-y-1/2 z-50 w-full">
          <div className="daisuthuonghieu-prev cursor-pointer">
            <CircleChevronLeft
              className={"size-10 text-white/50 hover:text-white"}
            />
          </div>
          <div className="daisuthuonghieu-next cursor-pointer absolute right-0">
            <CircleChevronRight
              className={"size-10 text-white/50 hover:text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideDaiSu;
