import React, { useState } from 'react'
import { Layout, Button, theme, Avatar, Dropdown, Space } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    HomeOutlined,
    LoginOutlined,
    RollbackOutlined,
} from '@ant-design/icons';
import {
    Menu,
    MenuItem,
} from "react-pro-sidebar";
import { useNavigate } from 'react-router-dom';
import logo from '../../../images/logo-admin.png';
import { Outlet } from 'react-router-dom';
import './style.css'

const { Header, Sider, Content } = Layout;

const AdminLayout = () => {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handlePage = (href) => {
        navigate(href);
    };

    const handleLogout = () => {
        localStorage.clear()
        navigate("/admin/login");
    };
    const items = [
        {
            label: <button onClick={handleLogout} > Đăng Nhập</button >,
            key: '0',
            icon: <LoginOutlined />,
        },
        {
            type: 'divider',
        },
        {
            label: <button onClick={handleLogout} > Quay Lại</button >,
            key: '3',
            icon: <RollbackOutlined />,
        },
    ];
    return (
        <div>
            <Layout style={{ minHeight: '100vh', }}>
                <Sider trigger={null} collapsible collapsed={collapsed} theme="light ">
                    <div className="demo-logo-vertical p-5">
                        <img src={logo} alt="logo" />
                    </div>

                    <Menu>
                        <MenuItem className="font-medium text-[15px]"
                            active={window.location.pathname === "/admin"}
                            icon={<HomeOutlined />}
                            onClick={() => handlePage("/admin")}
                        >
                            Trang chủ
                        </MenuItem>
                        <MenuItem className="font-medium text-[15px]"
                            active={window.location.pathname === "/user"}
                            icon={<UserOutlined />}
                            onClick={() => handlePage("/user")}
                        >
                            Quản Lý User
                        </MenuItem>
                    </Menu>
                </Sider>
                <Layout>
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    >
                        <div className='flex justify-between items-center'>
                            <div className='flex items-center'>
                                <Button
                                    type="text"
                                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                    onClick={() => setCollapsed(!collapsed)}
                                    style={{
                                        fontSize: '16px',
                                        width: 64,
                                        height: 64,
                                    }}
                                />

                            </div>
                            <div className='flex justify-end mr-10'>
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    trigger={['click']}
                                >
                                    <a href='/#' onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <Avatar size="large" icon={<UserOutlined />} />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                        </div>

                    </Header>
                    <Content
                        style={{
                            margin: '12px',
                            padding: 10,
                            minHeight: 280,
                            background: colorBgContainer,
                        }}
                    >
                        {/* sử dụng Outlet đển fill content từ page con */}
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default AdminLayout