import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form, Input, Avatar, message } from 'antd';
import './style.css';
import Lottie from 'lottie-react';
import animationAdmin from './Lottie/admin.json';
import tezdy from '../../../images/TEZDy.png';
import { BsFacebook, BsTelegram } from 'react-icons/bs';
import { AiFillYoutube, AiFillTwitterCircle } from 'react-icons/ai';
import {
    UserOutlined, LockOutlined
} from '@ant-design/icons';
import { login } from '../../../services/user';
import { TOKEN_NAME } from '../../../utilities/constants';
import { setAuthorization } from '../../../services/axios-client';

const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

export default function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, SetPassword] = useState('');
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            navigate('/admin');
        }
        // eslint-disable-next-line
    }, [token])

    async function loginAdmin() {
        let item = { username, password };
        try {
            let result = await login(JSON.stringify(item));
            if (result?.status === 200) {
                localStorage.setItem(TOKEN_NAME, result.data.token)
                message.success("Đăng nhập thành công!!");
                setAuthorization(result?.data.token);
                navigate('/admin');
            }
        } catch (error) {
            if (error?.response.status === 400) {
                if (error?.response.data.status === 1) {
                    message.error("Sai mật khẩu !!")
                }
                else if (error?.response.data.status === 2) {
                    message.error("Tài khoản đã bị khóa")
                }
                else if (error?.response.data.status === 3) {
                    message.error("Tài khoản không tồn tại")
                }
            }
            else {
                console.log(error)
            }
        }
    }
    return (
        <div className='relative'>
            <div className=' w-full  flex justify-around items-center'>
                <div className='w-[48%] max-h-[100vh]'>
                    <Lottie className='boy' animationData={animationAdmin} loop={true}
                    />
                </div>
                <div className='w-[52%] h-[100vh] box-sha bg-white'>
                    <h3 className='text-[40px] font-black text-[#ffb300] text-center mt-36 my-[3rem]'>CHÀO MỪNG BẠN ĐẾN VỚI TRANG ADMIN</h3>
                    {/* <h3 className='text-[30px] font-bold text-[#9068f1] text-center'>ĐĂNG NHẬP</h3> */}
                    <div className=''>
                        <div className='w-full flex justify-center'>
                            <Avatar className='box'
                                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                src={tezdy}
                            />
                        </div>
                        <div className='flex justify-center w-full'>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                style={{
                                    maxWidth: 600,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Tài khoản"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập tên tài khoản!',
                                        },
                                    ]}
                                >
                                    <Input size="large" onChange={(e) => setUsername(e.target.value)} placeholder="username" prefix={<UserOutlined />} />
                                </Form.Item>

                                <Form.Item
                                    label="Mật Khẩu"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập mật của bạn!',
                                        },
                                    ]}
                                >
                                    <Input.Password onChange={(e) => SetPassword(e.target.value)} size="large" placeholder="password" prefix={<LockOutlined />} />
                                </Form.Item>

                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button onClick={loginAdmin} className='bg-[#9068f1] hover:!bg-[#3e2776]' style={{ width: '100%', }} type="primary" htmlType="submit">
                                        Đăng Nhập
                                    </Button>
                                </Form.Item>
                                <div>
                                    <ul className='flex justify-center items-center gap-5'>
                                        <li className='cursor-pointer text-[35px] text-[#fe0000]'><AiFillYoutube /></li>
                                        <li className='cursor-pointer text-[30px] text-[#1877f2]'><BsFacebook /></li>
                                        <li className='cursor-pointer text-[35px] text-[#1d9bf0]'><AiFillTwitterCircle /></li>
                                        <li className='cursor-pointer text-[30px] text-[#29a9ea]'><BsTelegram /></li>
                                    </ul>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
