import React, { useEffect } from "react";
import "./content.css";
import video from "../../images/video.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import modal from "../../images/icon-modal.png";
import girl from "../../images/grid.png";
import daotao from "../../images/icon-daotao.png";
import moitruong from "../../images/icon-moitruong.png";
import chinhsach from "../../images/icon-chinhsach.png";
import uytin from "../../images/icon-uytin.png";
import logo789w from "../../images/logo789bet.png";
import logojun88w from "../../images/logojun88w.png";
import logonew88w from "../../images/logonew88w.png";
import logof8betw from "../../images/logof8w.png";
import logohi88w from "../../images/logohi88w.png";
import logoshbetw from "../../images/logoshbetw.png";
import logomb66w from "../../images/logomb66w.png";
import logook9w from "../../images/logook9.png";
import logo78winw from "../../images/logo78win.png";

import s1 from "../../images/b1.png";
import s2 from "../../images/b2.png";
import s3 from "../../images/b3.png";
import s4 from "../../images/b4.png";
import s5 from "../../images/b5.png";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { modalData } from "../../utilities/CSNS";
import AOS from "aos";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import SlideDaiSu from "../Sliders/SlideDaiSu/SlideDaiSu";
import SlideHauDai from "../Sliders/SlideHauDai/SlideHauDai";
import DoiTac from "../Sliders/SlideDoiTac/DoiTac";
import VideoPlayer from "../PlayVideo/PlayVideo";

const Card = () => {
  const [open, setOpen] = React.useState(false);
  const [modalCotent, setModalContent] = React.useState();
  const [modalTitle, setModalTitle] = React.useState();
  const videoUrls = [
    "https://okvip.info/gioithieu.mp4",
    "https://okvip.info/chungtoilaai.mp4",
    "https://okvip.info/Final_Logo.mp4",
  ];
  useEffect(() => {
    AOS.init();
  }, []);
  const handleOpen = (order) => {
    const m = modalData.find((x) => x.order === order);
    console.log(order);
    setModalTitle(m.title);
    setModalContent(m.content);
    setOpen(true);
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidestoscroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidestoscroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidestoscroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidestoscroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bgs !bg-gradient-to-r to-[#0a0911] via-[#313771] from-[#0a0911]">
      <div className="max-w-[1600px] mx-auto ">
        <div>
          <div className="mx-auto py-3 md:my-[20px]">
            <div className="pb-5 ">
              <h2 className="text-[#fff] text-[35px] font-semibold text-center">
                CHÚNG TÔI LÀ AI?
              </h2>
              <p className="text-justify lg:text-center pb-3 font-normal text-[18px] p-2.5 lg:text-[21px] text-white">
                OKVIP là một trong những tập đoàn phát triển Game online hàng
                đầu châu Á. Hiện nay, OKVIP phát triển và chiếm thị phần lớn
                mảng game bài tại các nước như Campuchia, Dubai, Philippines và
                Singapore. Với trụ sở chính ở Mộc bài – Campuchia với hàng chục
                ngàn nhân sự. OKVIP tuyển dụng việc làm Campuchia với nhiều vị
                trí.
              </p>
            </div>
            <VideoPlayer className="w-full" videos={videoUrls} />
            {/* <video
              className="w-full"
              data-aos="fade-up"
              controls
              muted
              loop
              poster={video}
            >
              <source src={"https://okvip.info/OKVIP.mp4"} type="video/mp4" />
            </video> */}
          </div>
        </div>
        <div>
          <div>
            <SlideDaiSu />
          </div>
          <div className="mt-8">
            <h3 className="text-[#fff] text-[30px] md:text-[41px] pt-2 font-bold text-center">
              THÀNH VIÊN LIÊN MINH
            </h3>
          </div>
          {/* thanh vien mobi  */}
          <div className="lg:hidden grid grid-cols-5 gap-3 text-white font-medium pt-5">
            <div className="flex items-center flex-col justify-center">
              <img className="size-[54px]" src={logo789w} alt="" />
              <p>789BET</p>
            </div>
            <div className="flex items-center flex-col justify-center">
              <img className="size-[54px]" src={logojun88w} alt="" />
              <p>Jun88</p>
            </div>
            <div className="flex items-center flex-col justify-center">
              <img className="size-[54px]" src={logonew88w} alt="" />
              <p>NEW88</p>
            </div>
            <div className="flex items-center flex-col justify-center">
              <img className="size-[54px]" src={logof8betw} alt="" />
              <p>F8BET</p>
            </div>
            <div className="flex items-center flex-col justify-center">
              <img className="size-[54px]" src={logohi88w} alt="" />
              <p>Hi88</p>
            </div>
            <div className="flex items-center flex-col justify-center">
              <img className="size-[54px]" src={logoshbetw} alt="" />
              <p>SHBET</p>
            </div>
            <div className="flex items-center flex-col justify-center">
              <img className="size-[54px]" src={logomb66w} alt="" />
              <p>MB66</p>
            </div>
            <div className="flex items-center flex-col justify-center">
              <img className="size-[54px]" src={logook9w} alt="" />
              <p>OK9</p>
            </div>
            <div className="flex items-center flex-col justify-center">
              <img className="size-[54px]" src={logo78winw} alt="" />
              <p>78win</p>
            </div>
          </div>
          <SlideHauDai />
        </div>
        <div className="mt-8 lg:mt-14 relative">
          <div>
            <h5 className="text-[25px] text-center md:text-[30px] lg:text-[40px] text-[#FFF] font-normal">
              ĐỐI TÁC CHIẾN LƯỢC
            </h5>
            <div>
              <p className="text-[15px] my-2.5 text-center lg:text-[20px] text-white px-2.5">
                Liên minh OKVIP không chỉ liên minh với những nền tảng giải trí
                hàng đầu, mà còn mở rộng xây dựng quan hệ đối tác chiến lược với
                các thương hiệu danh tiếng khác.
              </p>
            </div>
            <DoiTac />
          </div>
        </div>
        <div data-aos="fade-down" className="text-center">
          <h6 className="text-[20px] md:text-[30px] lg:text-[40px] text-[#fff] font-semibold">
            QUY TRÌNH PHỎNG VẤN VÀ NHẬN VIỆC
          </h6>
        </div>
        <div data-aos="zoom-in" className="py-5 gap-2">
          <Slider {...settings}>
            <div>
              <div className="px-2">
                <img loading="lazy" src={s1} alt="buoc1" />
              </div>
            </div>
            <div className="px-2">
              <img loading="lazy" src={s2} alt="buoc2" />
            </div>
            <div className="px-2">
              <img loading="lazy" src={s3} alt="buoc3" />
            </div>
            <div className="px-2">
              <img loading="lazy" src={s4} alt="buoc4" />
            </div>
            <div className="px-2">
              <img loading="lazy" src={s5} alt="buoc5" />
            </div>
          </Slider>
        </div>
        <div className="pb-5">
          <div data-aos="fade-down">
            <h5 className="text-[#fff] text-[30px] md:text-[40px] font-bold text-center">
              TỔNG QUAN CÔNG TY
            </h5>
          </div>
          <div className="flex justify-center lg:justify-between items-center pt-[32px]">
            <div className="w-full md:w-[80%] lg:w-[50%]">
              <div data-aos="fade-right">
                <h4 className="text-[30px] md:text-[40px] font-bold text-[#ff9900] text-center lg:text-start">
                  CHÍNH SÁCH<b className="text-[#fff]"> NHÂN SỰ</b>{" "}
                </h4>
              </div>
              <div
                data-aos="fade-up-right"
                onClick={() => {
                  handleOpen(1);
                }}
                className="daotaos bg-[#111e46] rounded-lg flex justify-between items-center p-1 px-3 my-2.5 cursor-pointer"
              >
                <div>
                  <img src={daotao} className="size-[56px]" alt="daotao" />
                </div>
                <div className="text-[18px] md:text-[29px]  font-bold text-[#fff]">
                  <h4>ĐÀO TẠO VÀ PHÁT TRIỂN</h4>
                </div>
                <div>
                  <img
                    loading="lazy"
                    src={modal}
                    className="size-[40px]"
                    alt="modal"
                  />
                </div>
              </div>
              <div
                data-aos="fade-up-right"
                className="moitruongs bg-[#111e46] rounded-lg  flex justify-between items-center p-1 px-3 my-2.5 cursor-pointer"
              >
                <div>
                  <img
                    loading="lazy"
                    src={moitruong}
                    className="size-[56px]"
                    alt="moitruong"
                  />
                </div>
                <div
                  onClick={() => {
                    handleOpen(2);
                  }}
                  className="text-[18px] md:text-[29px] font-bold text-[#fff]"
                >
                  <h4>MÔI TRƯỜNG LÀM VIỆC</h4>
                </div>
                <div>
                  <img
                    loading="lazy"
                    src={modal}
                    className="size-[40px]"
                    alt="modal"
                  />
                </div>
              </div>
              <div
                data-aos="fade-up-right"
                onClick={() => {
                  handleOpen(3);
                }}
                className="chinhsachs bg-[#111e46] rounded-lg  flex justify-between items-center p-1 px-3 my-2.5 cursor-pointer"
              >
                <div>
                  <img
                    loading="lazy"
                    src={chinhsach}
                    className="size-[56px]"
                    alt="daotao"
                  />
                </div>
                <div className="text-[18px] md:text-[29px] font-bold text-[#fff]">
                  <h4>CHÍNH SÁCH PHÚC LỢI</h4>
                </div>
                <div>
                  <img
                    loading="lazy"
                    src={modal}
                    className="size-[40px]"
                    alt="modal"
                  />
                </div>
              </div>
              <div
                data-aos="fade-up-right"
                className="uytins bg-[#111e46] rounded-lg  flex justify-between items-center p-1 px-3 my-2.5 cursor-pointer"
              >
                <div>
                  <img
                    loading="lazy"
                    src={uytin}
                    className="size-[56px]"
                    alt="daotao"
                  />
                </div>
                <div
                  onClick={() => {
                    handleOpen(4);
                  }}
                  className="text-[18px] md:text-[29px] font-bold text-[#fff]"
                >
                  <h4>UY TÍN HÀNG ĐẦU</h4>
                </div>
                <div>
                  <img
                    loading="lazy"
                    src={modal}
                    className="size-[40px]"
                    alt="modal"
                  />
                </div>
              </div>
            </div>
            <div data-aos="fade-up-left" className="hidden lg:block">
              <img loading="lazy" src={girl} alt="girl" />
            </div>
          </div>
          <div
            data-aos="fade-down"
            className="daolis bg-[#242432] rounded-xl text-center text-[#fff] py-5"
          >
            <h4 className="text-[20px] md:text-[30px] lg:text-[52px] font-bold ">
              ĐẾN VỚI NHAU LÀ MỘT SỰ KHỞI ĐẦU
            </h4>
            <p className="text-[15px] md:text-[20px] lg:text-[30px]  pt-[10px]">
              LÀM VIỆC VỚI NHAU LÀ SỰ TIẾN BỘ <br className="block lg:hidden" />
              GIỮ ĐƯỢC CHÂN NHAU LÀ THÀNH CÔNG
            </p>
          </div>
        </div>

        {/* modal */}
        <Dialog
          open={open}
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogHeader>{modalTitle || ""}</DialogHeader>
          <DialogBody divider className="">
            {modalCotent || ""}
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => {
                setOpen(false);
              }}
              className="mr-1"
            >
              <span>Đóng</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};

export default Card;
