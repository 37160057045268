import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  const location = useLocation();
  const hasParams = location.pathname.endsWith("/");
  return (
    <>
      <div>
        {/* header */}
        <div>
          <Header />
        </div>
        <div className="!bg-gradient-to-r to-[#0a0911] via-[#313771] from-[#0a0911]">
          <Outlet />
        </div>
        {/* footer */}
        <div className={`${!hasParams ? "block" : "hidden"}`}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
