// src/VideoPlayer.js
import React, { useState, useRef } from "react";

const VideoPlayer = ({ videos }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  // Hàm để xử lý khi video kết thúc
  const handleVideoEnded = () => {
    if (currentVideoIndex < videos.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } else {
      setCurrentVideoIndex(0); // Quay lại video đầu tiên nếu đã hết danh sách
    }
  };

  // Hàm để phát video hiện tại
  const startPlaying = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  // Tự động phát video khi đã tải xong và đã được bắt đầu
  const handleLoadedData = () => {
    if (isPlaying && videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className=" mx-auto text-center">
      <video
        ref={videoRef}
        src={videos[currentVideoIndex]}
        controls
        muted
        autoPlay
        onEnded={handleVideoEnded}
        onLoadedData={handleLoadedData}
        className="w-full mx-auto"
      />
      {/* {!isPlaying && (
        <button
          onClick={startPlaying}
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        >
          Bắt đầu
        </button>
      )} */}
      {/* <p className="mt-4">
        Video {currentVideoIndex + 1} / {videos.length}
      </p> */}
    </div>
  );
};

export default VideoPlayer;
