import { Modal } from "antd";
import React from "react";
import { IconPlayCircle } from "../Icon";
import more from "../../images/morehome.png";
import blog from "../../images/blog.jpg";
const ModalBlog = ({ isModalOpenBlog, setIsModalOpenBlog }) => {
  const showModal = () => {
    setIsModalOpenBlog(true);
  };
  const handleOk = () => {
    setIsModalOpenBlog(false);
  };
  const handleCancel = () => {
    setIsModalOpenBlog(false);
  };
  return (
    <div>
      <div className="flex justify-center lg:justify-start items-center gap-x-1 my-2.5">
        <button
          onClick={showModal}
          to={"#"}
          className="flex justify-center lg:justify-start items-center gap-x-1"
        >
          <img src={more} alt="" />{" "}
          <span className="underline text-[#ff9900]">Xem thêm về bài viết</span>
        </button>
      </div>

      <Modal
        className=""
        footer={false}
        width={860}
        height={650}
        title={
          <div className=" text-2xl font-bold uppercase">
            Liên minh <span className="text-[#ff9900]">OKVIP</span> là gì?
          </div>
        }
        open={isModalOpenBlog}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="text-[16px] !bg-gradient-to-r to-[#0a0911] via-[#313771] from-[#0a0911] text-white p-2.5 rounded-md">
          <h2 className="text-[#ff9900] text-2xl font-bold text-center py-2.5">
            HIỂU THẾ NÀO VỀ LIÊN MINH OKVIP
          </h2>
          <p>
            LIÊN MINH OKVIP là một trang Liên Minh các thương hiệu giải trí trực
            tuyến hàng đầu Châu Á. <br /> Định vị của OKVIP là một Liên Minh,
            điều này tạo nên sự khác biệt so với các nhà cái hay trang games
            riêng lẻ. Tại Trang chủ Liên Minh OKVIP, không diễn ra các hoạt động
            cá cược, không yêu cầu khách hàng nạp tiền hay cung cấp thông tin cá
            nhân phức tạp. Thay vào đó, khách hàng có thể nhận được nhiều ưu đãi
            miễn phí và những giá trị to lớn khác. <br /> Thuật ngữ “Liên Minh”
            còn thể hiện sự gắn kết, sức mạnh vượt trội và sự khác biệt hoàn
            toàn so với những nhà cái thông thường.
          </p>
          <br />
          <p>
            Liên Minh OKVIP hứa hẹn sẽ là một cộng đồng vững mạnh, không chỉ là
            một Liên Minh của những thương hiệu giải trí hàng đầu Châu Á mà còn
            hơn thế nữa. Tại Liên Minh OKVIP, bạn sẽ tìm thấy mọi lựa chọn giải
            trí mơ ước, bao gồm hệ thống Game show nhận thưởng miễn phí và vô số
            nhà cái uy tín với kho game phong phú đồ sộ, bảo mật, an toàn. Không
            có lý do gì mà bạn không tham gia cộng đồng Liên Minh OKVIP để trải
            nghiệm niềm vui và cơ hội trúng lớn.
          </p>
          <div className="my-2.5">
            <img loading="lazy" src={blog} alt="" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalBlog;
