import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { contentHauDai } from "./Contents";
import { Link } from "react-router-dom";
const SlideHauDai = () => {
  var menu = [
    "789BET",
    "Jun88",
    "NEW88",
    "SHBET",
    "F8BET",
    "Hi88",
    "MB66",
    "OK9",
    "78win",
  ];
  const pagination = {
    el: ".paginationLM",
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span style=" color: #fff; font-size: 20px; font-weight: 500; padding-top: 10px;" class="' +
        className +
        '">' +
        menu[index] +
        "</span>"
      );
    },
  };
  return (
    <div>
      <div className="hidden lg:block relative">
        <Swiper
          pagination={pagination}
          modules={[Autoplay, Pagination]}
          spaceBetween={30}
          navigation={true}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          className="mySwiper relative border-b border-gray-600 pb-[1.5px]"
          data-aos="zoom-in-up"
          data-aos-duration="2000"
        >
          {contentHauDai.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col-reverse gap-x-10 lg:flex-row justify-center items-center pt-5">
                <div className="w-full lg:w-1/2 h-auto flex justify-center items-center">
                  <div className="flex items-center justify-center justify-items-center max-h-[580px] p-10 lg:w-full xl:w-2/3">
                    <img
                      loading="lazy"
                      className="object-contain h-full block mx-auto w-full !lg:w-full xl:w-2/3"
                      src={item.imgPhone}
                      alt="phone789"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <div className="text-[#535353] text-[16px] font-normal text-justify">
                    <div className="w-[150px]">
                      <img
                        className=""
                        src={item.imgLogo}
                        alt={"logo" + item.name}
                      />
                    </div>
                    <div className="border-b border-white/55 my-2 !text-white" />
                    <p className="text-white pr-2.5">{item.content}</p>
                  </div>
                  <div className="flex items-center gap-4 mt-2.5 py-5">
                    <div
                      className="h-[200px] w-[180px] bg-white rounded-xl flex justify-center items-center
                      flex-col"
                    >
                      <div className="size-[140px] ">
                        <img
                          className="w-full h-full !object-contain pt-2.5"
                          src={item.qr}
                          alt="qr789"
                        />
                      </div>
                      <div className="text-[13px] font-bold text-black block p-2.5">
                        Tải xuống APP
                      </div>
                    </div>
                    <Link to={item.href} target="_blank">
                      <div className="h-[220px]">
                        <img
                          className="w-40 h-40"
                          src={item.link}
                          alt="link789"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="paginationLM"></div>
      </div>
    </div>
  );
};

export default SlideHauDai;
