import { Modal } from "antd";
import React from "react";
// import video from "../../images/videos/OKVIP.mp4";
import { IconPlayCircle } from "../Icon";

const ModalVideo = ({ isModalOpen, setIsModalOpen }) => {
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div
        onClick={showModal}
        className="flex items-center gap-x-2 border rounded-lg px-5 py-2 w-fit cursor-pointer hover:bg-white/55"
      >
        <IconPlayCircle className={"size-10"} />
        Video giới thiệu
      </div>
      <Modal
        footer={false}
        width={1000}
        height={650}
        title="GIỚI THIỆU LIÊN MINH OKVIP"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <video
          style={{ maxWidth: "100%" }}
          className="object-fill w-full h-full"
          loop
          autoPlay
          muted
          controls
        >
          <source src={"https://okvip.info/OKVIP.mp4"} type="video/mp4" />
        </video>
      </Modal>
    </div>
  );
};

export default ModalVideo;
