import React, { useState } from "react";
import cl1 from "../../../images/doitac1.png";
import cl2 from "../../../images/doitac2.png";
import cl3 from "../../../images/doitac3.png";
import "./doitac.css";
import {
  CircleChevronLeft,
  CircleChevronRight,
  IconCaretDown,
} from "../../Icon";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Thumbs } from "swiper/modules";
const slides = [
  {
    imgUrl: cl1,
    content: "Liên kết với Casino - Khách sạn New Venetian",
  },
  {
    imgUrl: cl2,
    content: "Liên kết với Casino - Khách sạn LuongSon",
  },
  {
    imgUrl: cl3,
    content: "Liên kết với Casino - Khách sạn OKPay",
  },
  {
    imgUrl: cl1,
    content: "Liên kết với Casino - Khách sạn New Venetian",
  },
  {
    imgUrl: cl2,
    content: "Liên kết với Casino - Khách sạn LuongSon",
  },
];
const DoiTac = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [conten, setConten] = useState(slides[0].content);
  const [sideActive, setSideActive] = useState(0);
  return (
    <div className="max-w-[1000px] mx-auto my-10">
      <div className="hidden lg:block mx-auto w-full relative">
        <Swiper
          slidesPerView={"auto"}
          className="swiperdoitac mb-5"
          loop={true}
          spaceBetween={10}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".arrow-right",
            prevEl: ".arrow-left",
          }}
          modules={[Navigation, Autoplay, Pagination]}
          breakpoints={{
            425: {
              slidesPerView: 1,
              spaceBetween: 10,
              centeredSlides: true,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 10,
              centeredSlides: false,
            },
            767: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide
              key={index}
              className={`w-full flex flex-col items-center justify-center`}
            >
              <img
                loading="lazy"
                className={``}
                src={slide.imgUrl}
                alt={`Slide ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="my-5 z-50 flex justify-between items-center absolute top-[40%] w-full ">
          <div className="arrow-left z-50 left-0 bottom-0 text-white bg-[#191919] cursor-pointer rounded-full hidden lg:block">
            <CircleChevronLeft
              className={
                "w-8 text-[#3b3b3b] hover:bg-[#3b3b3b] hover:text-black rounded-full"
              }
            />
          </div>
          <div className="arrow-right z-50 right-0 absolute  bottom-0 text-white bg-[#191919] cursor-pointer rounded-full hidden lg:block">
            <CircleChevronRight
              className={
                "w-8 text-[#3b3b3b] hover:bg-[#3b3b3b] hover:text-black rounded-full"
              }
            />
          </div>
        </div>
      </div>
      <div className="block lg:hidden ">
        <div className="block lg:hidden relative">
          <Swiper
            onSlideChange={(swiper) => {
              setConten(slides[swiper.realIndex].content);
              setSideActive(swiper.realIndex);
            }}
            slidesPerView={3}
            className="swiperdoitac"
            loop={true}
            spaceBetween={10}
            centeredSlides={true}
            initialSlide={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Navigation, Thumbs, Autoplay]}
            breakpoints={{
              375: {
                slidesPerView: 1,
                spaceBetween: 10,
                centeredSlides: true,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 10,
                centeredSlides: true,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
                centeredSlides: false,
              },
              767: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide
                key={index}
                className={`w-full flex flex-col items-center justify-center`}
              >
                <img
                  loading="lazy"
                  className={``}
                  src={slide.imgUrl}
                  alt={`Slide ${index + 1}`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="flex justify-center items-center ">
            <IconCaretDown className={"size-10 text-[#515bba] "} />
          </div>
          {/* thumb slider  */}
          <Swiper
            onSwiper={setThumbsSwiper}
            initialSlide={0}
            loop={true}
            spaceBetween={20}
            slidesPerView={3}
            modules={[Navigation, Thumbs]}
            className="swiperthumb mt-5 mb-5 relative w-fit !flex !justify-center !items-center"
          >
            {slides.slice(0, 3).map((slide, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className=" opacity-30 max-w-[60px] "
                  onClick={() => setConten(slide.content)}
                >
                  <div className="!size-[65px]">
                    <img
                      loading="lazy"
                      src={slide.imgUrl}
                      alt=""
                      className="object-contain rounded-lg"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* <div className="text-white/55 text-center">{conten}</div> */}
        </div>
      </div>
    </div>
  );
};

export default DoiTac;
