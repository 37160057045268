import React, { useRef, useState } from "react";
import "./caursel.css";
import { BiSolidUser, BiSolidTimeFive } from "react-icons/bi";
import it from "../../images/workIT.png";

import { WORKData } from "../../utilities/WORKData";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { AngleDown, CircleChevronLeft, CircleChevronRight } from "../Icon";
const Caursel = () => {
  const [visibleItems, setVisibleItems] = useState(2);
  const loadMore = () => {
    setVisibleItems(visibleItems + 4);
  };
  const swiperRef = useRef(null);

  return (
    <div className="w-full bg-[#000] py-5">
      <section className=" hidden lg:block max-w-[1600px] mx-auto">
        <div className="relative">
          <div className="w-[250px] absolute top-0 left-0 h-[170px] bg-gradient-to-r from-black via-black/85 to-black/10 z-50"></div>
          <div className="w-[250px] absolute top-0 right-0 h-[170px] bg-gradient-to-l from-black via-black/85 to-black/10 z-50"></div>
          <Swiper
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            slidesPerView={3}
            spaceBetween={30}
            modules={[Autoplay, Navigation, Pagination]}
            navigation={{
              nextEl: ".arrow-right",
              prevEl: ".arrow-left",
            }}
            loop={true}
            pagination={{
              el: ".pagination",
              clickable: true,
              renderBullet: (index, className) => {
                return `<span class=" ${className}" >  </span>`;
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              1440: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
          >
            {WORKData.map((item, index) => (
              <SwiperSlide key={index}>
                <a href="/#" className="block">
                  <div className="w-full flex flex-col justify-start">
                    <div className="flex justify-start">
                      <div className="w-[125px]">
                        <img
                          className="min-w-[125px] w-full object-contain"
                          src={item.avatar}
                          alt={item.title}
                          title={item.title}
                        />
                      </div>
                      <div className="w-full bg-gradient-to-r from-[#0a0911] via-[#30366f] to-[#0a0911] rounded-lg px-10 py-5 block z-[-1] bg-job min-h-[120px] -ml-10">
                        <div className="text-white line-clamp-2 capitalize text-[19px] w-full h-14 text-left pl-2.5">
                          {item.title}
                        </div>
                        <div className="flex gap-x-5">
                          <h6 className="text-[#FFA91E] text-[18px] font-light flex items-center gap-2">
                            <BiSolidUser /> {item.people}
                          </h6>
                          <h6 className="text-[#FFA91E] text-[18px] font-light flex items-center gap-2">
                            <BiSolidTimeFive /> {item.time} Toàn thời gian
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end mr-10">
                      <div className="bg-orange-500 rounded-full text-[15px] font-bold px-3 py-1">
                        {item.salary}
                      </div>
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="mt-2.5 flex justify-between items-center">
            <div className="arrow-left z-50 left-0 bottom-0 text-white bg-[#191919] cursor-pointer rounded-full">
              <CircleChevronLeft
                className={
                  "w-8 text-[#3b3b3b] hover:bg-[#3b3b3b] hover:text-black rounded-full"
                }
              />
            </div>
            <div className="pagination flex justify-center px-2.5 items-center py-2 bottom-10 text-center z-[99999] bg-[#191919] !max-w-fit rounded-3xl"></div>
            <div className="arrow-right z-50 right-0 bottom-0 text-white bg-[#191919] cursor-pointer rounded-full">
              <CircleChevronRight
                className={
                  "w-8 text-[#3b3b3b] hover:bg-[#3b3b3b] hover:text-black rounded-full"
                }
              />
            </div>
          </div>
        </div>
      </section>
      <section className="block lg:hidden px-2 py-2">
        <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
          {WORKData.slice(0, visibleItems).map((item, index) => (
            <div key={index} className="block">
              <div className="w-full flex flex-col justify-start">
                <div className="flex justify-start">
                  <div className="w-[125px]">
                    <img
                      className="min-w-[90px] w-full object-contain relative z-50"
                      src={item.avatar}
                      alt={item.titleMB}
                      title={item.titleMB}
                    />
                  </div>
                  <div className="w-full rounded-lg block bg-jobmobi min-h-[120px] py-5 -ml-10 z-0">
                    <div className="!text-white line-clamp-2 capitalize text-[20px] w-full pt-2.5 text-left pl-2.5">
                      <span className="capitalize !text-white pl-10">
                        {item.titleMB}
                      </span>
                    </div>
                    <div className="flex gap-x-2.5 pl-12">
                      <h6 className="text-[#FFA91E] text-[15px] font-light flex items-center">
                        <BiSolidUser /> {item.people}
                      </h6>
                      <h6 className="text-[#FFA91E] text-[13px] font-light flex items-center">
                        <BiSolidTimeFive /> Toàn thời gian
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="w-full flex justify-center items-center font-bold -mt-2">
                  <div className="bg-orange-500 w-fit rounded-full text-[15px] font-bold px-3 py-1">
                    {item.salary}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center py-2 m-2">
          {visibleItems < WORKData.length && (
            <button
              className="text-gray-800 font-bold p-2 rounded-md "
              onClick={loadMore}
            >
              Xem thêm <AngleDown className={"w-3 inline-block align-middle"} />
            </button>
          )}
        </div>
      </section>
    </div>
  );
};

export default Caursel;
