import word1 from "../images/word1.png";
import word2 from "../images/word2.png";
import word3 from "../images/word3.png";
import word4 from "../images/word4.png";
import word5 from "../images/word5.png";
import word6 from "../images/word6.png";
import word7 from "../images/word7.png";
import word8 from "../images/word8.png";
import word9 from "../images/word9.png";
import word10 from "../images/word10.png";
import word11 from "../images/word11.png";
import word12 from "../images/word12.png";
import word13 from "../images/word13.png";
import word14 from "../images/word14.png";
import word15 from "../images/word15.png";
import word16 from "../images/word16.png";
import word17 from "../images/word17.png";
import word18 from "../images/word18.png";
import word19 from "../images/word19.png";
import word20 from "../images/word20.png";
import word21 from "../images/word21.png";

export const WORKData = [
  {
    title: "Việc Làm Sale Online tại Việt Nam",
    avatar: word1,
    people: "1000 người",
    salary: "7 triệu VNĐ - 30 triệu VNĐ",
    titleMB: "Sale Online",
    salaryMB: "7 - 30 triệu",
  },
  {
    title: "việc làm chuyên viên IT phần cứng tại campuchia - OKVIP",
    avatar: word2,
    people: "4 - 6 Người",
    salary: "25 triệu VNĐ - 30 triệu VNĐ",
    titleMB: "it phần cứng",
    salaryMB: "25 - 30 triệu",
  },
  {
    title: "việc làm booking quảng cáo tại campuchia - OKVIP",
    avatar: word3,
    people: "4 - 6 Người",
    salary: "25 triệu VNĐ - 30 triệu VNĐ",
    titleMB: "Booking QC",
    salaryMB: "25 - 30 triệu",
  },
  {
    title: "việc làm phó quản lý và quản lý truyền thông tại campuchia - OKVIP",
    avatar: word4,
    people: "4 - 6 Người",
    salary: "30 triệu VNĐ - 50 triệu VNĐ",
    titleMB: "PQL PR",
    salaryMB: "30 - 50 triệu",
  },
  {
    title: "việc làm phó quản lý và quản lý truyền thông tại campuchia - OKVIP",
    avatar: word5,
    people: "4 - 6 Người",
    salary: "30 triệu VNĐ - 50 triệu VNĐ",
    titleMB: "Media",
    salaryMB: "22 - 24 triệu",
  },
  {
    title: "việc làm bình luận viên bóng đá tại campuchia - OKVIP",
    avatar: word6,
    people: "4 - 6 Người",
    salary: "25 triệu VNĐ - 30 triệu VNĐ",
    titleMB: "BLV Bóng Đá",
    salaryMB: "25 - 30 triệu",
  },
  {
    title: "việc làm mobile apps developer tại campuchia - OKVIP",
    avatar: word7,
    people: "4 - 6 Người",
    salary: "45 triệu VNĐ - 55 triệu VNĐ",
    titleMB: "Mobile Apps",
    salaryMB: "45 - 55 triệu",
  },
  {
    title: "Việc làm IT website (Full stack) tại campuchia - OKVIP",
    avatar: word8,
    people: "4 - 6 Người",
    salary: "40 triệu VNĐ - 50 triệu VNĐ",
    titleMB: "Mobile Apps",
    salaryMB: "45 - 55 triệu",
  },
  {
    title: "Việc làm thiết kế hình ảnh tại campuchia - OKVIP",
    avatar: word9,
    people: "4 - 6 Người",
    salary: "35 triệu VNĐ - 40 triệu VNĐ",
    titleMB: "IT Website",
    salaryMB: "40 - 50 triệu",
  },
  {
    title: "việc làm thiết kế video tại campuchia - OKVIP",
    avatar: word10,
    people: "4 - 6 Người",
    salary: "40 triệu VNĐ - 50 triệu VNĐ",
    titleMB: "Editor Video",
    salaryMB: "40 - 50 triệu",
  },
  {
    title: "Việc làm facebook ads tại campuchia - OKVIP",
    avatar: word11,
    people: "4 - 6 Người",
    salary: "30 triệu VNĐ - 40 triệu VNĐ",
    titleMB: "Facebook Ads",
    salaryMB: "30 - 40 triệu",
  },
  {
    title: "Việc làm google ads tại campuchia - OKVIP",
    avatar: word12,
    people: "4 - 6 Người",
    salary: "30 triệu VNĐ - 40 triệu VNĐ",
    titleMB: "Facebook Ads",
    salaryMB: "30 - 40 triệu",
  },
  {
    title: "việc làm sale tại campuchia - OKVIP",
    avatar: word13,
    people: "100 - 150 người",
    salary: "7 triệu VNĐ - 30 triệu VNĐ",
    titleMB: "Sale Offline",
    salaryMB: "24 - 30 triệu",
  },
  {
    title: "việc làm trợ lý oA tại campuchia - OKVIP",
    avatar: word14,
    people: "4 - 6 Người",
    salary: "20 triệu VNĐ - 23 triệu VNĐ",
    titleMB: "Trợ lý OA",
    salaryMB: "20 - 23 triệu",
  },
  {
    title: "việc làm hành chính tại campuchia - OKVIP",
    avatar: word15,
    people: "4 - 6 Người",
    salary: "18 triệu VNĐ - 30 triệu VNĐ",
    titleMB: "Hành Chính",
    salaryMB: "18 - 30 triệu",
  },
  {
    title: "việc làm BA - bran assistant tại campuchia - OKVIP",
    avatar: word16,
    people: "4 - 6 Người",
    salary: "100 Triệu VNĐ",
    titleMB: "Brand Assistant",
    salaryMB: "100 triệu",
  },
  {
    title: "Việc làm seo tại campuchia - OKVIP",
    avatar: word17,
    people: "10 - 15 người",
    salary: "25 triệu VNĐ - 30 triệu VNĐ",
    titleMB: "SEO",
    salaryMB: "25 - 30 triệu",
  },
  {
    title: "Việc làm hậu đài tại campuchia - OKVIP",
    avatar: word18,
    people: "4 - 6 Người",
    salary: "25 triệu VNĐ - 45 triệu VNĐ",
    titleMB: "Hậu Đài",
    salaryMB: "25 - 45 triệu",
  },
  {
    title: "việc làm tổ trưởng marketing tại campuchia - OKVIP",
    avatar: word19,
    people: "4 - 6 Người",
    salary: "34 triệu VNĐ - 100 triệu VNĐ",
    titleMB: "Leader MKT",
    salaryMB: "34 - 100 triệu ",
  },
  {
    title: "Việc làm nhân sự tuyển dụng tại campuchia - OKVIP",
    avatar: word20,
    people: "4 - 6 Người",
    salary: "25 triệu VNĐ - 30 triệu VNĐ",
    titleMB: "HR",
    salaryMB: "27 - 40 triệu",
  },
  {
    title: "Việc làm digital marketing tại campuchia - OKVIP",
    avatar: word21,
    people: "5 - 10 người",
    salary: "50 triệu VNĐ - 70 triệu VNĐ",
    titleMB: "Digital Marketing",
    salaryMB: "50 - 70 triệu",
  },
];
