import React from "react";
import logo from "../../../images/logo.png";
import logoSogan from "../../../images/logo-OKVIP.png";
import btn from "../../../images/btn-ungtuyen.png";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const hasParams = location.pathname.endsWith("/");
  console.log("hasParams", hasParams);
  return (
    <div>
      <section className="bg-gradient-to-r to-[#0b0912] from-[#0b0912] px-2 border-b border-gray-500/55 ">
        <div className="max-w-[1600px] mx-auto flex justify-between items-center">
          <a
            className={`  w-[35%] md:w-[20%] lg:w-[15%] py-5 ${
              !hasParams ? "block " : "mx-auto hidden"
            }`}
            href="/"
          >
            <img className="w-full" src={logo} alt="logo" />
          </a>
          <a
            className={`${
              !hasParams ? " hidden" : "mx-auto block"
            }   w-[35%] md:w-[20%] lg:w-[15%] py-5 `}
            href="/"
          >
            <img className="w-full" src={logoSogan} alt="logo" />
          </a>

          <div
            className={`${
              !hasParams ? "block" : "hidden"
            } flex justify-between items-center gap-x-10 text-white rounded-full lg:border lg:p-1 bg-[#46475c]`}
          >
            <span className="pl-3 hidden lg:block">
              Bạn đang tìm kiếm công việc?
            </span>
            <a
              href="https://okvipfinger.com/cv"
              className="text-white bg-[#0b0d1a] lg:bg-[#ff9900] lg:px-3 lg:py-1 rounded-full text-center"
            >
              <img src={btn} alt="" className="rounded-xl lg:hidden" />
              <span className="hidden lg:block">Nộp hồ sơ ứng tuyển</span>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
