import {
  GET_PAGING_USERS_API_URL,
  INSERT_USER_API_URL,
  LOGIN_API_URL,
  UPLOAD_IMAGE,
  UPDATE_USER_API_URL,
  GET_BY_USER_API_URL,
} from "../api_urls";
import { AxiosClient } from "../axios-client";
const axiosClient = new AxiosClient();
export function login(data) {
  return axiosClient.post(LOGIN_API_URL, data);
}

export function getPaging(data) {
  return axiosClient.get(GET_PAGING_USERS_API_URL, data);
}

export function insert(data) {
  return axiosClient.post(INSERT_USER_API_URL, data);
}

export function update(id, data) {
  return axiosClient.put(`${UPDATE_USER_API_URL}/${id}`, data);
}

export function getByUserCode(data) {
  return axiosClient.get(`${GET_BY_USER_API_URL}/${data.userCode}`, data);
}

export function uploadImage(data) {
  return axiosClient.uploadImage(UPLOAD_IMAGE, data);
}
