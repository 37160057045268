import React from "react";
import "./profile.css";
import iconAv from "../../images/Accept.png";

import bgavatar from "../../images/bg-avatar.png";
const Profile = ({ hrData }) => {
  return (
    <div className="bg-profile px-2 pb-10">
      <div className="max-w-[1200px] mx-auto  lg:py-[44px] ">
        <div className="flex flex-col md:flex-row justify-center items-center gap-[20px] xl:gap-[55px]">
          <div className="relative flex flex-col justify-center items-center px-2 mb-5">
            <div className={`py-2.5 `}>
              <img
                className="avatar rounded-full min-w-[350px] w-[250px] h-[350px] lg:minw-[420px] lg:w-[420px] lg:h-[420px]"
                src={`${process.env.REACT_APP_IMAGE_DOMAIN}${hrData?.avatar}`}
                alt="Avatar"
              />
            </div>
            <div
              className="absolute text-center -bottom-11 w-[350px] lg:w-[420px] h-[150px] lg:h-[120px] name md:p-3 "
              style={{
                backgroundImage: `url(${bgavatar}) `,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <h3 className="text-[20px] mt-11 md:mt-8 lg:mt-3 lg:text-[23px] text-[#fff] font-black">
                {hrData?.fullName}
              </h3>
              <h5 className="text-[20px] lg:text-[23px] text-[#fff] font-medium">
                {hrData?.subName}
              </h5>{" "}
              <div className="text-[15px] lg:text-[20px] text-[#fff] font-medium pt-3">
                {hrData?.bio}
              </div>
            </div>
            <div className="absolute bottom-[75px] md:bottom-[60px] right-[5px] md:right-[25px] lg:right-[35px]">
              <img className="w-14" src={iconAv} alt="icon" />
            </div>
          </div>
          <div className="w-full md:w-[80%] lg:w-[40%] mt-10">
            <div
              className={`flex justify-start lg:justify-between w-full gap-x-0 h-[60px]`}
            >
              <a
                href={hrData?.telegramLink}
                className={`tele flex justify-between items-center text-center w-full`}
              >
                <div className="text-[18px] xl:text-[23px] w-full text-center font-bold text-[#fff] h-[50px] py-1.5">
                  <h5>{hrData?.telegramName}</h5>
                </div>
              </a>
            </div>
            <div className="flex justify-between w-full gap-x-0 h-[60px]">
              <a
                href={hrData?.zaloLink}
                className="zalo flex justify-between items-center text-center w-full"
              >
                <div className="text-[18px] xl:text-[23px] w-full text-center font-bold text-[#fff] h-[50px] py-1.5">
                  <h5>{hrData?.zaloName}</h5>
                </div>
              </a>
            </div>
            <div className="flex justify-between w-full gap-x-0 h-[60px]">
              <a
                href={hrData?.facebookLink}
                className="facebook flex justify-between items-center text-center w-full"
              >
                <div className="text-[18px] xl:text-[23px] w-full text-center font-bold text-[#fff] h-[50px] py-1.5">
                  <h5>{hrData?.facebookName}</h5>
                </div>
              </a>
            </div>
            <div className="flex justify-between w-full gap-x-0 h-[60px]">
              <a
                href={hrData?.youtubeLink}
                className="youtube flex justify-between items-center text-center w-full"
              >
                <div className="text-[16px] pl-5 lg:pl-0 lg:text-[16px] pt-2.5 w-full text-center font-bold text-[#fff] h-[50px] py-1.5">
                  <h5 className="text-center">{hrData?.youtubeName}</h5>
                </div>
              </a>
            </div>
            <div className="flex justify-between w-full gap-x-0 h-[60px]">
              <a
                href={hrData?.tiktokLink}
                className="tiktok flex justify-between items-center text-center w-full"
              >
                <div className="text-[18px] xl:text-[23px] w-full text-center font-bold text-[#fff] h-[50px] py-1.5">
                  <h5>{hrData?.tiktokName}</h5>
                </div>
              </a>
            </div>
            <div className="flex justify-between w-full gap-x-0 h-[60px]">
              <a
                href={hrData?.gmailLink}
                className="gmail flex justify-between items-center text-center w-full"
              >
                <div className="text-[18px] xl:text-[23px] w-full text-center font-bold text-[#fff] h-[50px] py-1.5">
                  <h5>{hrData?.gmailName}</h5>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
